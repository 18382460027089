import dynamic from 'next/dynamic';

import { CookData, isAuthorProfilePage } from '@hubcms/domain-cook';
import { ComponentProps } from 'react';
import { getSectionRegioIdentifier } from '@hubcms/utils-my-section';
import { getTeaserPropsOptions } from '@hubcms/utils-teaser';

const Section = dynamic(() => import('./section'));
const Article = dynamic(() => import('./article'));

export function ResolutionComponent({ cookData }: { cookData: CookData }) {
  const teaserPropsOptions = getTeaserPropsOptions({
    todaysDate: cookData.todaysDate,
    sectionParams: cookData.sectionParams,
  });

  if (cookData.resolution.context === 'sec' || isAuthorProfilePage(cookData)) {
    return <Section data={cookData} teaserPropsOptions={teaserPropsOptions} />;
  }
  if (cookData.resolution.context === 'art') {
    return <Article data={cookData as ComponentProps<typeof Article>['data']} teaserPropsOptions={teaserPropsOptions} />;
  }
  throw Error('No component found to render as no supported type (or none at all) was returned from cook');
}
