import { ReactElement } from 'react';
import Head from 'next/head';

export function GTMDataLayer(): ReactElement {
  return (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];`,
        }}
      />
    </Head>
  );
}
