import Head from 'next/head';
import { ReactElement } from 'react';

import { useCook } from '@hubcms/data-access-cook';
import { CookData, isArticleContext } from '@hubcms/domain-cook';

import { mapStructuredDataNewsArticle } from '../../../utils/mapStructuredDataNewsArticle';

const CreativeWorkSchema = (): ReactElement | null => {
  const cookData: CookData = useCook();

  if (!isArticleContext(cookData.context)) {
    return null;
  }

  const specs = mapStructuredDataNewsArticle(cookData.context);

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(specs, null, 2),
        }}
      />
    </Head>
  );
};

export default CreativeWorkSchema;
