import { ReactElement } from 'react';
import Head from 'next/head';

import {
  isAudioVisualArticleContext,
  isArticleContext,
  isContextWithElements,
  isElementTextField,
  CookData,
  isTextualArticleContext,
  isExtraPageContext,
  isTagPage,
} from '@hubcms/domain-cook';

type MetaTagsProps = {
  cookData: CookData;
};

export default function MetaTags({ cookData }: MetaTagsProps): ReactElement {
  const elements = isContextWithElements(cookData.context) ? cookData?.context?.elements : [];

  let robotsValue = ['index', 'follow'];

  const intro = elements.find(el => el.type === 'intro');
  let descriptionValue = '';
  if (isTextualArticleContext(cookData.context)) {
    const paragraph = cookData.context.elements.find(el => el.type === 'paragraph');
    // @ts-expect-error TS2532
    descriptionValue = (isElementTextField(paragraph?.fields?.[0]) && paragraph.fields[0].value) || '';
  }
  if (isElementTextField(intro?.fields?.[0])) {
    descriptionValue = intro?.fields?.[0]?.value ?? '';
  }

  if (
    cookData?.resolution?.context === 'sec' &&
    (cookData.declaredParams?.['section.seo.description'] || cookData.sectionParams?.['section.seo.description'])
  ) {
    descriptionValue =
      cookData.declaredParams?.['section.seo.description'] ?? cookData.sectionParams?.['section.seo.description'];
  }

  if (isTagPage(cookData)) {
    // @ts-expect-error TS2339
    robotsValue = cookData.intro[0]?.fields?.indexable === true ? ['index', 'max-image-preview:large'] : ['noindex'];
    // @ts-expect-error TS2339
    robotsValue.push(cookData.intro[0]?.fields?.followlinks === true ? 'follow' : 'nofollow');
    // @ts-expect-error TS2339
    if (cookData.intro[0]?.isPremium) {
      robotsValue.push('noarchive');
    }
    // @ts-expect-error TS2339
    if (cookData.intro[0]?.fields?.metaDescription) {
      // @ts-expect-error TS2339
      descriptionValue = cookData.intro[0]?.fields?.metaDescription;
    }
  }

  if (isArticleContext(cookData.context) || isExtraPageContext(cookData.context)) {
    // @ts-expect-error TS2339
    robotsValue = cookData.context.fields?.indexable === true ? ['index', 'max-image-preview:large'] : ['noindex'];
    // @ts-expect-error TS2339
    robotsValue.push(cookData.context.fields?.followlinks === true ? 'follow' : 'nofollow');
    // @ts-expect-error TS2339
    if (cookData.context.fields.isPremium) {
      robotsValue.push('noarchive');
    }
    // @ts-expect-error TS2339
    if (cookData.context.fields?.metaDescription) {
      // @ts-expect-error TS2339
      descriptionValue = cookData.context.fields?.metaDescription;
    } else if (isAudioVisualArticleContext(cookData?.context)) {
      // @ts-expect-error TS2322
      descriptionValue = cookData?.context.fields?.caption;
    }
  }

  return (
    <Head>
      {!!descriptionValue && <meta name="description" content={descriptionValue} />}
      {robotsValue?.length > 0 && <meta name="robots" content={robotsValue.join(',')} />}
    </Head>
  );
}
